/** DadosLocalController.js
 *  Parte da diretiva dataclickLocal
 */

(function(app, notifications, buildInputValidationClasses) {

  app.controller('DadosLocalController', ['$scope', 'LocalResource',
    function($scope, LocalResource) {
      $scope.buildInputValidationClasses = function(formName, fieldName) {
        return buildInputValidationClasses($scope, formName, fieldName);
      };
      $scope.submitDadosLocal = function() {
        $scope.shared.pendingRequests++;
        var promise = '';
        if (!$scope.shared.dadosLocal.id) {
          promise = LocalResource.save($scope.shared.dadosLocal).$promise;
        }else {
          promise = LocalResource.update({id: $scope.shared.dadosLocal.id},
                                            $scope.shared.dadosLocal).$promise;
        }
        promise.then(function(response) {
              notifications.notifySuccess('Local salvo com sucesso');
              if (response.generatedId) {
                $scope.shared.dadosLocal.id = response.generatedId;
              }
              $scope.shared.loadLocais();
              $scope.shared.setStep('lista');
            })
            .catch(function(error) {
              notifications.notifyError('Falha ao salvar local', error.data.errors);
            })
            .finally(function(response) {
              $scope.shared.pendingRequests--;
            });
      };
      $scope.$on('newLocal', function(e) {
        $scope.dadosLocalForm.$setPristine();
      });
    }]);

})(angular.module(DataClick.apiModuleName), DataClick.Notifications,
     DataClick.buildInputValidationClasses);
