(function(DataClick) {
  DataClick.buildInputValidationClasses = function($scope, formName, inputName) {

    if (!$scope[formName]) { return; }

    if (!$scope[formName][inputName]) { return; }

    return {'has-feedback': $scope[formName].$submitted || $scope[formName][inputName].$dirty,
            'has-success': ($scope[formName].$submitted || $scope[formName][inputName].$dirty) && $scope[formName][inputName].$valid,
            'has-error': ($scope[formName].$submitted || $scope[formName][inputName].$dirty) && $scope[formName][inputName].$invalid};
  };
})(DataClick);
