/**
 * FilaController.js
 * Parte da diretiva dataclickLocal
 */

(function(app, notifications, saveEntity, _) {

  app.controller('FilaController',['$scope', 'IdentificadorFilaResource', 'FilaResource',
    function($scope, IdentificadorFilaResource, FilaResource) {

      function getFeedbackClasses(form, isValid) {
        return {
          'has-feedback': form.$dirty,
          'has-error': form.$dirty && !isValid,
          'has-success': form.$dirty && isValid
        };
      }

      $scope.validationClassIdentificador = function(fila, form) {
        if (!fila) {
          return {};
        }
        return getFeedbackClasses(form, fila.identificador);
      };

      $scope.validationClassIntervaloInicio = function(identificador, intervalo, form) {
        if (!intervalo || form.$pristine) { return {}; };

        var isValid = intervalo.isIntervaloInicioValid() && identificador.intervaloPodeSerAdicionado(intervalo);
        return getFeedbackClasses(form, isValid);
      };

      $scope.excluirIdentificador = function(fila) {
        if (fila.intervalos.length > 0) {
          return;
        }

        var index = $scope.currentSecao.filas.indexOf(fila);
        if (index > -1) {
          $scope.currentSecao.filas.splice(index, 1);
        }
      };

      $scope.intervaloValido = function(identificador, intervalo) {
        if (!identificador || !intervalo) { return false; }

        return identificador.intervaloPodeSerAdicionado(intervalo) &&
               intervalo.isIntervaloInicioValid() &&
               intervalo.isIntervaloFimValid();
      };

      $scope.validationClassIntervaloFim = function(identificador, intervalo, form) {
        if (!intervalo || form.$pristine) { return {}; };

        var isValid = intervalo.isIntervaloFimValid() && identificador.intervaloPodeSerAdicionado(intervalo);

        return getFeedbackClasses(form, isValid);
      };

      $scope.inativateFila = function(fila) {
        $scope.modalPendingRequests++;
        FilaResource.delete({}, {id: fila.id})
        .$promise
        .then(function(response) {
          notifications.notifySuccess('Fila inativada com sucesso.');
          $scope.shared.setSecao($scope.currentSecao.id);
        })
        .catch(function(error) {
          notifications.notifyError('Falha ao inativar fila.', error.data.errors);
        })
        .finally(function() {
          $scope.modalPendingRequests--;
        });
      };

      $scope.filasValid = function() {
        if (!$scope.currentSecao) {
          return true;
        }

        var intervalosValidos = _.all($scope.currentSecao.filas, function(fila) {
          return _.all(fila.intervalos, function(intervalo) {
            return $scope.intervaloValido(fila, intervalo);
          });
        });

        return $scope.identificadorGlobalForm.$valid && intervalosValidos;
      };

      $scope.save = function() {
        var request = {};
        request.secaoId = $scope.currentSecao.id;

        request.items = _.chain($scope.currentSecao.filas)
                         .map(function(fila) {
                           return _.map(fila.intervalos, function(intervalo) {
                             return {'identificador': fila.identificador,
                                     'intervaloInicio': intervalo.intervaloInicio,
                                     'intervaloFim': intervalo.intervaloFim};
                           });
                         })
                         .flatten()
                         .value();

        $scope.shared.pendingRequests++;

        FilaResource.save(request)
                    .$promise
                    .then(function(response) {
                      notifications.notifySuccess('Filas salvas com sucesso.');
                      $scope.shared.backToSecaoList(true);
                    })
                    .catch(function(error) {
                      notifications.notifyError('Falha ao salvas as filas.', error.data.errors);
                    })
                    .finally(function() {
                      $scope.shared.pendingRequests--;
                    });
      };

    }]);

})(angular.module(DataClick.apiModuleName), DataClick.Notifications, DataClick.SaveEntity, _);
