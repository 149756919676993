(function(app, global) {

  app.factory('LocalResource', ['$resource', function($resource)  {
    return $resource(global.dataclickApiUrl + '/api/v1/local/:id/:page/:size', {
      id: '@id',
      page: '@page',
      size: '@size'
    }, {
      'update': {
        method: 'PUT'
      }
    });
  }]);

  app.factory('MapeamentoResource', ['$resource', function($resource) {
    return $resource(global.dataclickApiUrl + '/api/v1/local/mapeamento/:id', {
      id: '@id'
    }, {
      'update': {
        method: 'PUT'
      }
    });
  }]);

  app.factory('SetorResource', ['$resource', function($resource) {
    return $resource(global.dataclickApiUrl + '/api/v1/local/setor/:id', {
      id: '@id'
    }, {
      'update': {
        method: 'PUT'
      }
    });
  }]);

  app.factory('SecaoResource', ['$resource', function($resource) {
    return $resource(global.dataclickApiUrl + '/api/v1/local/secao/:id', {
      id: '@id'
    }, {
      'update': {
        method: 'PUT'
      }
    });
  }]);

  app.factory('FilaResource', ['$resource', function($resource) {
    return $resource(global.dataclickApiUrl + '/api/v1/local/fila/:id', {
      id: '@id'
    }, {
      'update': {
        method: 'PUT'
      }
    });
  }]);

  app.factory('IdentificadorFilaResource', ['$resource', function($resource) {
    return $resource(global.dataclickApiUrl + '/api/v1/local/filaIdentificador', {}, {
      'update': {
        method: 'PUT'
      }
    });
  }]);

})(angular.module(DataClick.apiModuleName), window);
