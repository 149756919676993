(function(global, app) {

  app.factory('ViaCepResource',['$resource', function($resource) {
    return $resource('http://viacep.com.br/ws/:cep/json/', {cep: '@cep'});
  }]);

  app.factory('CepPostmonResource',['$resource', function($resource) {
    return $resource('http://api.postmon.com.br/v1/cep/:cep', {cep: '@cep'});
  }]);

  app.factory('CepCorreioControlResource',['$resource', function($resource) {
    return $resource('http://cep.correiocontrol.com.br/:cep.json', {cep: '@cep'});
  }]);

  app.factory('CepSearchService',['ViaCepResource', 'CepPostmonResource', 'CepCorreioControlResource', '$q',
    function(ViaCepResource, CepPostmonResource, CepCorreioControlResource, $q) {
      return function(cep, callback) {
        var request = {cep: cep};
        var requestNumbersOnly = {cep: cep.replace('-', '')};

        function viaCep() {
          var promise = ViaCepResource.get(request).$promise;
          var defObj = $q.defer();

          promise.then(function(r) {
            if (r.erro) {
              defObj.reject('erro : true');
              return;
            }
            result = {'endereco': r.logradouro, 'bairro': r.bairro, 'cidade': r.localidade, 'estado': r.uf};
            callback(result);
            defObj.resolve(result);
          });
          return defObj.promise;
        }

        function postmon() {
          var promise = CepPostmonResource.get(request).$promise;
          promise.then(function(r) {
            result = {'endereco': r.logradouro, 'bairro': r.bairro, 'cidade': r.cidade, 'estado': r.estado};
            callback(result);
          });
          return promise;
        }

        function correioControl() {
          var promise = CepCorreioControlResource.get(requestNumbersOnly).$promise;
          promise.then(function(r) {
            result = {'endereco': r.logradouro, 'bairro': r.bairro, 'cidade': r.localidade, 'estado': r.uf};
            callback(result);
          });
          return promise;
        }

        viaCep().catch(function() { return postmon();})
                .catch(function() { return correioControl();})
                .catch(function() { callback(null); });
      };
    }]);

  app.directive('dataclickEndereco', ['CepSearchService', function(CepSearchService) {
    return {
      scope: {dados: '=', form: '='},
      templateUrl: global.dataclickApiUrl + 'directives/dataclickEndereco.html',
      controller: ['$scope',function($scope) {
        $scope.buildInputValidationClasses = function(formName, inputName) {
          if (!$scope[formName]) { return; }

          if (!$scope[formName][inputName]) { return; }
          return {'has-feedback': $scope[formName].$submitted || $scope[formName][inputName].$dirty,
                  'has-success': ($scope[formName].$submitted || $scope[formName][inputName].$dirty) && $scope[formName][inputName].$valid,
                  'has-error': ($scope[formName].$submitted || $scope[formName][inputName].$dirty) && $scope[formName][inputName].$invalid};
        };

        $scope.states = ['AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS',
                        'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR',
                        'SC', 'SP', 'SE', 'TO'];

        $scope.searchCEP = function() {
              if ($scope.form.cep.$invalid) {
                return;
              }

              CepSearchService($scope.dados.cep, function(result) {
                $scope.dados.endereco = result.endereco;
                $scope.dados.bairro = result.bairro;
                $scope.dados.cidade = result.cidade;
                $scope.dados.estado = result.estado;
              });
            };

      }]
    };
  }]);

})(window, angular.module(DataClick.apiModuleName));
