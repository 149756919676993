(function(DataClick, toastr, _) {

  'use strict';

  toastr.options = {'closeButton': true,
                     'debug': false,
                     'newestOnTop': false,
                     'progressBar': false,
                     'positionClass': 'toast-top-full-width',
                     'preventDuplicates': false,
                     'onclick': null,
                     'showDuration': '300',
                     'hideDuration': '1000',
                     'timeOut': '5000',
                     'extendedTimeOut': '1000',
                     'showEasing': 'swing',
                     'hideEasing': 'linear',
                     'showMethod': 'fadeIn',
                     'hideMethod': 'fadeOut'};

  DataClick.Notifications = {};

  DataClick.Notifications.notificationType = {'Error': 'Error',
                           'Warning': 'Warning',
                           'Success': 'Success',
                           'Info': 'Info'};

  DataClick.Notifications.notify = function(title, messages, type) {

    var messagesUlLi = '';

    if (messages && messages.length > 0) {
      messagesUlLi = '<ul>' + _.reduce(messages, function(acc, item) { return acc + '<li>' + item + '</li>'; }, '') + '</ul>';
    }

    switch (type){
      case DataClick.Notifications.notificationType.Error:
        toastr.error(messagesUlLi, title);
      break;
      case DataClick.Notifications.notificationType.Success:
        toastr.success(messagesUlLi, title);
      break;
      case DataClick.Notifications.notificationType.Warning:
        toastr.warning(messagesUlLi, title);
      break;
      case DataClick.Notifications.notificationType.Info:
        toastr.info(messagesUlLi, title);
      break;
    }
  };

  DataClick.Notifications.notifySuccess = function(title) {
      DataClick.Notifications.notify(title,[], DataClick.Notifications.notificationType.Success);
    };

  DataClick.Notifications.notifyError = function(title, messages) {
      DataClick.Notifications.notify(title, messages, DataClick.Notifications.notificationType.Error);
    };

  DataClick.Notifications.notifyWarning = function(title, messages) {
      DataClick.Notifications.notify(title, messages, DataClick.Notifications.notificationType.Warning);
    };

  DataClick.Notifications.notifyInfo = function(title, messages) {
      DataClick.Notifications.notify(title, messages, DataClick.Notifications.notificationType.Info);
    };

})(DataClick, toastr, _);
