(function(Models, _) {

  if (!Models.Locais) { Models.Locais = {}; }

  Models.Locais.Mapeamento = function(mapeamento) {
    for (prop in mapeamento) { this[prop] = mapeamento[prop]; }
    if (mapeamento) {
      this.setores = _.map(mapeamento.setores, function(i) { return new Models.Locais.Setor(i); });
      this.onEdit = false;
    }else {
      this.setores = [];
      this.onEdit = true;
    }

    var self = this;

    var dataBackup = '';

    this.edit = function() {
      dataBackup = self.nome;
      self.onEdit = true;
    };

    this.obterQuantidadeLugares = function() {
      if (self.setores.length == 0) {
        return 0;
      }

      return _.reduce(self.setores, function(acc, i) {
        return acc + i.obterQuantidadeLugares();
      }, 0);
    };

    this.cancelEdit = function() {
      self.nome = dataBackup ;
      self.onEdit = false;
    };

    this.getErrors = function() {
      var errors = [];

      if (!self.nome) {
        errors.push('Informe o nome');
      }

      if (!self.localId) {
        errors.push('Informe o local');
      }

      return errors;
    };

    this.isValid = function() {
      return self.getErrors() == 0;
    };
  };

})(DataClick.Models, _);
