(function(global, app, notifications, models, locais, _, $) {

  app.directive('dataclickLocal', function() {
    return {
      templateUrl: global.dataclickApiUrl + 'directives/dataclickLocal.html',
      controller: ['$scope', '$location', '$rootScope', 'LocalResource',
        function($scope, $location, $rootScope, LocalResource) {

          setTimeout(function() { $('.hidden-pre-load').fadeIn('slow',function() { }); }, 30);

          $scope.shared = {};
          $scope.shared.currentStep = 'lista';
          $scope.shared.dadosLocal = {'setores': []};
          $scope.shared.pendingRequests = 0;

          $scope.shared.apiUrl = global.dataclickApiUrl;

          var currentTime = new Date().getTime();

          $scope.shared.updateTime = function() {
            currentTime = new Date().getTime();
          };

          $scope.shared.getTime = function() {
            return currentTime ;
          };

          $scope.shared.clear = function() {
            $scope.$broadcast('clear');
            $scope.shared.dadosLocal = {'setores': []};
          };

          $scope.shared.setLocal = function(id, callback) {
            $scope.shared.pendingRequests++;

            return LocalResource.get({id: id})
             .$promise
             .then(function(response) {
              $scope.shared.dadosLocal = new models.Local(response);
              if (callback) { callback(); }
            })
             .catch(function(error) {
              notifications.notifyError('Falha ao obter local', error.data.errors);
            })
             .finally(function() {
              $scope.shared.pendingRequests--;
            });
          };

          $scope.shared.setMapeamento = function(id) {
            $scope.pendingRequests++;

            MapeamentoResource.get({}, {id: id})
                              .$promise
                                  .then(function(response) {
                                    $scope.currentMapeamento = new Mapeamento(response);
                                  })
                                  .finally(function() {
                                    $scope.pendingRequests--;
                                  });
          };

          $scope.shared.dadosLocalClass = function() {
            return {'active': $scope.shared.currentStep == 'dadosLocal',
            'completed-step': $scope.shared.dadosLocal.id};
          };

          $scope.shared.cadastroSetorClass = function() {
            return {'active': $scope.shared.currentStep == 'cadastroSetor',
            'completed-step': $scope.shared.dadosLocal.setores.length > 0,
            'disabled': !$scope.shared.dadosLocal.id};
          };

          $scope.cadastroSetorAvailable = function() {
            return $scope.shared.dadosLocal.id;
          };

          $scope.mapeamentoAvailable = function() {
            return $scope.shared.dadosLocal.id;
          };

          $scope.mapeamentoDetalhesAvailable = function() {
            return $scope.shared.mapeamentoId;
          };

          $scope.setorAvailable = function() {
            return $scope.shared.currentMapeamento && $scope.shared.currentMapeamento.id;
          };

          $scope.secaoAvailable = function() {
            return $scope.shared.currentMapeamento && $scope.shared.currentMapeamento.id;
          };

          function stepAvailable(step) {
            if (!$scope[ step + 'Available' ]) { return true; }
            return $scope[ step + 'Available' ]();
          }

          $scope.shared.setStep = function(step) {
            var isStepAvailable = stepAvailable(step);
            if (!isStepAvailable) { return; }
            if (step == $scope.shared.currentStep) { return ;}
            $scope.shared.currentStep = step;
            window.location = '#/' + step;
          };

          $scope.newLocal = function() {
            $scope.shared.clear();
            $scope.shared.setStep('dadosLocal');
            $scope.$broadcast('newLocal');
          };

          $scope.shared.mapeamentoId = '';

          $rootScope.$on('$locationChangeStart', function(event, newUrl, oldUrl) {
            var currentUrl = $location.path().substring(1);
            var segments = currentUrl.split('/');
            var step = segments[0];

            $scope.shared.mapeamentoId = '';
            if (step.contains('mapeamentoDetalhes')) {
              $scope.shared.mapeamentoId = segments[1];
              step = 'mapeamentoDetalhes';
              //$scope.shared.setMapeamento($scope.shared.mapeamentoId);
            }

            if (!step) { step = 'lista'; }

            var isStepAvailable = stepAvailable(step);
            if (!isStepAvailable) { return;}

            if (step == $scope.shared.currentStep) { return ;}
            $scope.shared.currentStep = step;
          });

        }]};
  });

})(window, angular.module(DataClick.apiModuleName),
     DataClick.Notifications, DataClick.Models, DataClick.Models.Locais, _, $);

