(function(Models, _) {

  'use strict';

  if (!Models.Locais) { Models.Locais = {}; }

  Models.Locais.IdentificadorFila = function(secaoId, identificador, intervalos) {
    this.identificador = identificador;
    this.secaoId = secaoId;
    this.onEdit = !identificador;

    if (intervalos) {
      this.intervalos = _.map(intervalos,
                               function(item) { return new Models.Locais.Intervalo(item); });
    }else {
      this.intervalos = [];
    }

    var self = this;

    function getErrors() {
      var errors = [];

      if (!self.identificador) { errors.push('Informe o identificador.'); }

      return errors;
    }

    this.previousData = {};

    this.edit = function() {
      for (prop in self) { self.previousData[ prop ] = self[ prop ]; }
      self.onEdit = true;
    };

    function intervalo1InvadeIntervalo2(intervalo1, intervalo2) {
      return intervalo1.$$hashKey != intervalo2.$$hashKey &&
            (intervalo1.intervaloInicio > intervalo2.intervaloInicio &&
             intervalo1.intervaloInicio <= intervalo2.intervaloFim) ||
            (intervalo1.intervaloFim >= intervalo2.intervaloInicio &&
             intervalo1.intervaloFim < intervalo2.intervaloFim);
    }

    function intervalo1EnglobaIntervalo2(intervalo1, intervalo2) {
      return intervalo1.$$hashKey != intervalo2.$$hashKey &&
             (intervalo1.intervaloInicio <= intervalo2.intervaloInicio &&
              intervalo1.intervaloFim >= intervalo2.intervaloFim);
    }

    this.intervaloPodeSerAdicionado = function(intervalo) {
      intervalo.parseInt();
      _.each(self.intervalos,function(item) { item.parseInt(); });

      var invadeIntervalo = function(currentIntervalo) {
        return intervalo1InvadeIntervalo2(intervalo, currentIntervalo);
      };

      if (_.any(self.intervalos,invadeIntervalo)) { return false; }

      var englobaIntervalo = function(currentIntervalo) {
        return intervalo1EnglobaIntervalo2(intervalo, currentIntervalo);
      };

      if (_.any(self.intervalos, englobaIntervalo)) { return false; }

      return true;
    };

    this.obterQuantidadeLugares = function() {
      return _.reduce(self.intervalos, function(acc, i) {
        return acc + i.obterQuantidadeLugares();
      }, 0);
    };

    this.cancelEdit = function() {
      for (prop in self) { self[ prop ] = self.previousData[ prop ]; }
    };

    this.updateIntervalosIdentificadores = function() {
      _.each(self.intervalos,
              function(intervalo) { intervalo.identificador = self.identificador; });
    };

    this.revertIntervalosIdentificadores = function() {
      var identificador = intervalo.identificador = self.previousData.identificador;
      _.each(self.intervalos,
              function(intervalo) { intervalo.identificador = identificador; });
    };

    this.pendingSave = function() {
      return _.any(self.intervalos, function(item) { return !item.id; });
    };

    this.adicionarIntervalo = function() {
      var intervalo = new Models.Locais.Intervalo();
      intervalo.identificador = self.identificador;
      intervalo.secaoId = self.secaoId;
      self.intervalos.push(intervalo);
    };

    this.removerIntervalo = function(intervalo) {
      self.intervalos = _.filter(self.intervalos, function(i) {
        return i != intervalo;
      });
    };

    this.isValid = function() { return getErrors().length == 0; };
  };

})(DataClick.Models, _);
