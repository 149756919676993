(function(Models, _) {

  Models.Local = function(local) {
    for (prop in local) { this[prop] = local[prop]; }
    if (local) {
      this.mapeamentos = _.map(local.mapeamentos, function(i) { return new Models.Locais.Mapeamento(i); });
    }else {
      this.mapeamentos = [];
    }
  };

})(DataClick.Models, _);
