(function(DataClick, notifications) {

  DataClick.SaveEntity = function(entity, resource, successMsg, errorMsg, callback, $scope) {
    if (entity.isValid && !entity.isValid()) { return; }

    var promise = '';
    $scope.shared.pendingRequests++;

    if (entity.id) {
      promise = resource.update({id: entity.id}, entity).$promise;
    } else {
      promise = resource.save(entity).$promise;
    }

    promise.then(function(response) {
          notifications.notifySuccess(successMsg);
          if (response.generatedId) {
            entity.id = response.generatedId;
          }
          if (callback) { callback(); }
        }).catch(function(error) {
          notifications.notifyError(errorMsg, error.data.errors);
        }).finally(function() {
          $scope.shared.pendingRequests--;
        });
  };

})(DataClick, DataClick.Notifications);
