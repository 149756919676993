(function(Models, _) {

  if (!Models.Locais) { Models.Locais = {}; }

  Models.Locais.Setor = function(setor) {

    this.caminhoImagemMapa = '/';

    if (setor) {
      for (prop in setor) { this[prop] = setor[prop]; }
      this.secoes = _.chain(setor.secoes)
                     .filter(function(i) { return !i.geradaAutomaticamente; })
                     .map(function(i) { return new Models.Locais.Secao(i); })
                     .value();
      this.mapeamentoId = setor.mapeamento.id;
      this.onEdit = false;
    }else {
      this.secoes = [];
      this.secaoUnica = false;
      this.onEdit = true;
    }

    var self = this;

    this.adicionarSecao = function() {
      self.secoes.push(new Models.Locais.Secao());
    };

    var dataBackup = {};

    this.edit = function() {
      self.onEdit = true;
      dataBackup.nome = self.nome;
      dataBackup.secaoUnica = self.secaoUnica;
    };

    this.obterQuantidadeLugares = function() {
      if (self.secaoUnica) { return self.capacidade; }

      if (self.secoes.length == 0) { return 0; }

      return _.reduce(self.secoes, function(acc, i) {
        return acc + i.obterQuantidadeLugares();
      }, 0);
    };

    this.cancelEdit = function() {
      self.onEdit = false;
      self.nome = dataBackup.nome ;
      self.secaoUnica = dataBackup.secaoUnica ;
    };

    function getErrors() {
      var errors = [];
      if (!self.nome) {
        errors.push('Informe o nome.');
      }

      if (self.secaoUnica && !self.capacidade) {
        errors.push('Informe a capacidade.');
      }else if (self.secaoUnica && self.capacidade <= 0) {
        errors.push('A capacidade deve ser maior que 0.');
      }

      return errors;
    }

    this.isValid = function() {
      return getErrors().length == 0;
    };
  };

})(DataClick.Models, _);
