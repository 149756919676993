(function(global, app, notifications, models, locais, _, $) {

  app.directive('dataclickLocalManagement', function() {
    return {
			templateUrl: global.dataclickApiUrl + 'directives/dataclickLocalManagement.html',
			controller: ['$scope', '$location', '$rootScope', 'LocalResource',
        function($scope, $location, $rootScope, LocalResource) {

          setTimeout(function() { $('.hidden-pre-load').fadeIn('slow',function() { }); }, 30);

          $scope.shared = {};
          $scope.shared.currentStep = 'lista';
          $scope.shared.dadosLocal = {};
          $scope.shared.pendingRequests = 0;
          $scope.local = {};

          $scope.shared.apiUrl = global.dataclickApiUrl;

          $scope.shared.clear = function() {
            $scope.$broadcast('clear');
            $scope.shared.dadosLocal = {'setores': []};
          };

          $scope.detalhesLocal = function(local) {
            $scope.local = local;
            $scope.shared.setStep('detalhes');
          };

          $scope.shared.setLocal = function(id, callback) {
            $scope.shared.pendingRequests++;

            return LocalResource.get({id: id})
             .$promise
             .then(function(response) {
              $scope.shared.dadosLocal = new models.Local(response);
              if (callback) { callback(); }
            })
             .catch(function(error) {
              notifications.notifyError('Falha ao obter local', error.data.errors);
            })
             .finally(function() {
              $scope.shared.pendingRequests--;
            });
          };

          function stepAvailable(step) {
            if (!$scope[ step + 'Available' ]) { return true; }
            return $scope[ step + 'Available' ]();
          }

          $scope.shared.setStep = function(step) {
            var isStepAvailable = stepAvailable(step);
            if (!isStepAvailable) { return; }
            if (step == $scope.shared.currentStep) { return ;}
            $scope.shared.currentStep = step;
            window.location = '#/' + step;
          };

          $scope.newLocal = function() {
            $scope.shared.clear();
            $scope.shared.setStep('dadosLocal');
            $scope.$broadcast('newLocal');
          };

          $rootScope.$on('$locationChangeStart', function(event, newUrl, oldUrl) {
            var step = $location.path().substring(1);

            $scope.shared.mapeamentoId = '';
            if (step.contains('detalhes')) {
              $scope.shared.localId = step.split('/')[1];
              step = 'detalhes';
              $scope.shared.setLocal($scope.shared.localId);
            }

            if (!step) { step = 'lista'; }

            var isStepAvailable = stepAvailable(step);
            if (!isStepAvailable) { return;}

            if (step == $scope.shared.currentStep) { return ;}
            $scope.shared.currentStep = step;
          });

        }]};
  });

})(window, angular.module(DataClick.apiModuleName),
     DataClick.Notifications, DataClick.Models, DataClick.Models.Locais, _, $);

