/** MapeamentoController.js
 *  Parte da diretiva dataclickLocal
 */

(function(app, buildInputValidationClasses, locais, notifications, history, _, saveEntity) {

  app.controller('MapeamentoController', [
    '$scope', 'MapeamentoResource', 'SetorResource', 'SecaoResource',
    'FilaResource', 'IdentificadorFilaResource', 'Upload',
    function($scope, MapeamentoResource, SetorResource, SecaoResource,
              FilaResource, IdentificadorFilaResource, Upload) {

      $scope.currentSetor = null;
      $scope.shared.currentSecao = null;
      $scope.pendingRequests = 0;
      $scope.modalPendingRequests = 0;
      $scope.currentSetor = null;
      $scope.currentSecao = null;

      function getFeedbackClasses(form, isValid) {
        return {
          'has-feedback': form.$dirty,
          'has-error': form.$dirty && !isValid,
          'has-success': form.$dirty && isValid
        };
      }

      function cleanFormsValidations() {
        $scope.setorForm.$setPristine();
        $scope.nomeSecaoForm.$setPristine();
      }

      $scope.buildInputValidationClasses = function(formName, fieldName) {
        return buildInputValidationClasses($scope, formName, fieldName);
      };

      $scope.validationClassNomeSecao = function(secao, form) {
        if (!secao) { return {}; }
        return getFeedbackClasses(form, secao.nome);
      };

      $scope.isCurrentStepMapeamento = function() {
        return $scope.shared.currentStep == 'mapeamento' ||
        $scope.shared.currentStep == 'setor' ||
        $scope.shared.currentStep == 'secao';
      };

      $scope.saveMapeamento = function() {
        var callback = function() {
          $scope.mapeamentoForm.$setPristine();
          $scope.shared.currentMapeamento.onEdit = false;
          $scope.shared.loadLocais();
        };

        saveEntity($scope.shared.currentMapeamento, MapeamentoResource,
                  'Mapeamento salvo com sucesso', 'Falha ao salvar mapeamento', callback, $scope
        );
      };

    }]);

})(angular.module(DataClick.apiModuleName), DataClick.buildInputValidationClasses,
     DataClick.Models.Locais, DataClick.Notifications, history, _, DataClick.SaveEntity);
