(function(Models, _) {

  if (!Models.Locais) { Models.Locais = {}; }

  Models.Locais.Intervalo = function(dados) {
    var self = this;

    if (dados) {
      for (prop in dados) {this[prop] = dados[prop]; }
    }else {
      this.identificador = '';
      this.intervaloInicio = '';
      this.intervaloFim = '';
    }

    this.isIntervaloInicioValid = function() {
      return parseInt(self.intervaloInicio) > 0;
    };

    this.isIntervaloFimValid = function() {
      return parseInt(self.intervaloFim) > 0 && parseInt(self.intervaloFim) >= parseInt(self.intervaloInicio);
    };

    this.obterQuantidadeLugares = function() {
      self.parseInt();
      return self.intervaloFim - self.intervaloInicio + 1;
    };

    function getErrors() {
      var errors = [];

      if (!self.intervaloInicio) {
        errors.push('Informe o início do intervalo.');
      }

      if (!self.intervaloFim) {
        errors.push('Informe o fim do intervalo.');
      }else if (self.intervaloFim <= self.intervaloInicio) {
        errors.push('O fim do intervalo deve ser maior que o início.');
      }

      return errors;
    }

    this.isValid = function() { return getErrors().length == 0; };

    this.parseInt = function() {
      self.intervaloInicio = parseInt(self.intervaloInicio);
      self.intervaloFim = parseInt(self.intervaloFim);
    };
  };

})(DataClick.Models, _);

