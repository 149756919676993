/** LocalListController.js
 *  Parte da diretiva dataclickLocal
 */

(function(app, _, notifications, Mapeamento, Local, _) {

  app.controller('LocalListController',['$scope', 'LocalResource', 'MapeamentoResource',
                function($scope, LocalResource, MapeamentoResource) {
    $scope.pagination = {};
    $scope.pagination.currentPage = 1;
    $scope.pagination.pageSize = 15;
    $scope.pagination.totalPages = 1;

    $scope.loadPage = function (page) {
      if ($scope.pagination.currentPage === page) {
        return
      }

      $scope.pagination.currentPage = page;
      $scope.shared.loadLocais();
    };

    $scope.shared.setMapeamento = function(id, callback) {
      $scope.shared.pendingRequests++;

      MapeamentoResource.get({}, {id: id})
                        .$promise
                        .then(function(response) {
                                $scope.shared.currentMapeamento = new Mapeamento(response);
                                if (callback) {callback(); }
                              })
                        .finally(function() {
                                $scope.shared.pendingRequests--;
                              });
    };

    $scope.pagination.getPaginationRange = function() {
      return _.range(1, $scope.pagination.totalPages + 1);
    };

    $scope.editLocal = function(local) {
      $scope.shared.setLocal(local.id, function() {
        $scope.shared.setStep('dadosLocal');
      });
    };

    $scope.addMapeamento = function(local) {
      var mapeamento = new Mapeamento();
      $scope.shared.currentMapeamento = mapeamento;
      $scope.shared.dadosLocal = local;
      $scope.shared.currentMapeamento.localId = local.id;
      $scope.shared.setStep('mapeamento');
    };

    $scope.shared.editMapeamento = function(mapeamento) {
      $scope.shared.setMapeamento(mapeamento.id, function() {
        var local = _.find($scope.shared.locais, function(item) { return item.id == mapeamento.localId; });
        $scope.shared.dadosLocal = local;
        $scope.shared.setStep('mapeamento');
      });
    };

    $scope.inativateLocal = function(local) {
      if (!confirm('Deseja excluir o local? Esta ação é irreversível!')) {
        return;
      }

      $scope.shared.pendingRequests++;
      LocalResource.delete({}, {id: local.id})
                   .$promise
                         .then(function(response) {
                           notifications.notifySuccess('Local excluído com sucesso!');
                           $scope.shared.loadLocais();
                         })
                         .catch(function(error) {
                           notifications.notifyError('Falha ao excluir local!', error.data.errors);
                         })
                         .finally(function() {
                           $scope.shared.pendingRequests--;
                         });
    };

    $scope.inativateMapeamento = function(mapeamento) {

      if (!confirm('Deseja excluir o mapeamento? Esta ação é irreversível!')) {
        return;
      }

      $scope.shared.pendingRequests++;
      MapeamentoResource.delete({}, {id: mapeamento.id})
                   .$promise
                         .then(function(response) {
                           notifications.notifySuccess('Mapeamento inativado com sucesso.');
                           $scope.shared.loadLocais();
                         })
                         .catch(function(error) {
                           notifications.notifyError('Falha ao inativar mapeamento.', error.data.errors);
                         })
                         .finally(function() {
                           $scope.shared.pendingRequests--;
                         });
    };

    $scope.shared.loadLocais = function() {
      $scope.shared.pendingRequests++;

      var request = {
        'page': $scope.pagination.currentPage,
        'size': $scope.pagination.pageSize
      };

      var promise = LocalResource.get(request,
                function(response) {
                  $scope.shared.pendingRequests--;
                  $scope.shared.locais = _.map(response.locais, function(item) { return new Local(item); });
                  $scope.pagination.totalPages = response.pagesCount;
                },
                function(error) {
                  $scope.shared.pendingRequests--;
                }).$promise;

      return promise;
    };

    function _init() {
      $scope.shared.loadLocais();
    }

    _init();
  }]);
})(angular.module(DataClick.apiModuleName), _, DataClick.Notifications, DataClick.Models.Locais.Mapeamento,
   DataClick.Models.Local, _);
