(function(global, app, notifications, models, locais, _, $, saveEntity, buildInputValidationClasses, apiUrl,
          Mapeamento) {

  app.directive('dataclickLocalSetor', function() {
    return {
            templateUrl: global.dataclickApiUrl + 'directives/dataclickLocalSetor.html',
            scope: {'currentMapeamento': '=currentMapeamento',
                    'currentSecao': '=currentSecao',
                    'currentSetor': '=currentSetor',
                    'enableSetorManagement': '=enableSetorManagement'},
            controller: ['$scope', '$location', '$rootScope', 'LocalResource', 'SetorResource', 'SecaoResource',
                         'Upload', 'MapeamentoResource', '$q',
            function($scope, $location, $rootScope, LocalResource, SetorResource, SecaoResource, Upload,
                     MapeamentoResource, $q) {

              setTimeout(function() { $('.hidden-pre-load').fadeIn('slow',function() { }); }, 30);

              $scope.shared = {};
              $scope.shared.pendingRequests = 0;
              $scope.currentSetor = null;
              $scope.currentSecao = null;

              $scope.setorAvailable = function() {
                return $scope.currentMapeamento && $scope.currentMapeamento.id;
              };

              $scope.parseInt = function(val) {
                return parseInt(val);
              };

              $scope.undoSetorChanges = function() {
                $scope.currentSetor = {};
                for (var p in setorOriginal) {
                  $scope.currentSetor[p] = setorOriginal[p];
                }

                $scope.currentSetor.onEdit = false;
                if ($scope.currentSetor.secaoUnica) {
                  $scope.currentSetor = null;
                }else {
                  setEntity($scope.currentSetor.id, 'Falha ao carregar setor!', SetorResource,
                           function(data) { $scope.currentSetor = new locais.Setor(data); });
                }
              };

              $scope.buildInputValidationClasses = function(formName, fieldName) {
                return buildInputValidationClasses($scope, formName, fieldName);
              };

              $scope.secaoAvailable = function() {
                return $scope.currentMapeamento && $scope.currentMapeamento.id;
              };

              $scope.adicionarSetor = function(mapeamento) {
                var setor = new locais.Setor();
                setor.mapeamentoId = mapeamento.id;
                $scope.currentSetor = setor;
                window.location = '#/mapeamento/setor';
              };

              function setEntity(id, errorMsg, resource, thenCallback) {
                $scope.shared.pendingRequests++;
                resource.get({}, {
                  id: id
                }).$promise
                .then(function(data) {
                  thenCallback(data);
                })
                .catch(function(error) {
                  notifications.notifyError(errorMsg, error.data.errors);
                })
                .finally(function() {
                  $scope.shared.pendingRequests--;
                });
              }

              function setSetor(setorId) {
                setEntity(setorId, 'Falha ao carregar setor!', SetorResource,
                           function(data) { $scope.currentSetor = new locais.Setor(data); });
              }

              $scope.setSecao = function(secaoId) {
                setEntity(secaoId, 'Falha ao carregar seção!', SecaoResource,
                           function(data) { $scope.currentSecao = new locais.Secao(data); });
              };

              $scope.adicionarSecao = function() {
                if (!$scope.currentSetor.id) {
                  window.location = '#/mapeamento';
                  return;
                }
                var secao = new locais.Secao();
                $scope.currentSecao = secao;
                $scope.currentSecao.setorId = $scope.currentSetor.id;
                window.location = '#/mapeamento/secao';
              };

              $scope.editarSecao = function(secao) {
                $scope.currentSecao = {};
                for (var p in secao) {
                  $scope.currentSecao[p] = secao[p];
                }
                secaoOriginal = {};
                for (var p in secao) {
                  secaoOriginal[p] = secao[p];
                }
                window.location = '#/mapeamento/secao';
              };

              $scope.undoSecaoChanges = function() {
                $scope.currentSecao = {};
                for (var p in secaoOriginal) {
                  $scope.currentSecao[p] = secaoOriginal[p];
                }
                $scope.currentSecao.onEdit = false;
              };

              var setorOriginal = null;

              $scope.editarSetor = function(setor) {
                $scope.currentSetor = {};
                for (var p in setor) {
                  $scope.currentSetor[p] = setor[p];
                }

                setorOriginal = {};
                for (var p in setor) {
                  setorOriginal[p] = setor[p];
                }

                if ($scope.currentSetor.secaoUnica) {
                  $scope.currentSetor.onEdit = true;
                }else {
                  $scope.currentSetor.onEdit = false;
                }

                window.location = '#/mapeamento/setor';
                $scope.setorForm.$setPristine();
              };

              $scope.shared.backToSecaoList = function(updateMapeamento) {
                $scope.currentSecao = null;
                window.history.back();
                $scope.currentSetor.capacidade = $scope.currentSetor.obterQuantidadeLugares();

                if (updateMapeamento) {
                  var setorId = $scope.currentSetor.id;

                  $scope.shared
                        .setMapeamento($scope.currentMapeamento.id)
                        .then(function() {
                          restaurarSetorEmEdicao(setorId);
                        });
                }
              };

              $scope.shared.setMapeamento = function(id) {
                $scope.pendingRequests++;

                var deferred = $q.defer();

                MapeamentoResource.get({}, {id: id})
                                  .$promise
                                      .then(function(response) {
                                        $scope.currentMapeamento = new Mapeamento(response);
                                        deferred.resolve();
                                      })
                                      .catch(function() {
                                        deferred.reject();
                                      })
                                      .finally(function() {
                                        $scope.pendingRequests--;
                                      });

                return deferred.promise;
              };

              $rootScope.$on('$locationChangeStart', function(event, newUrl, oldUrl) {
                var currentUrl = $location.path().substring(1);
                var segments = currentUrl.split('/');
                var step = segments[0];
                var subStep = segments[1];

                if (step != 'mapeamento') { return; }

                if (!subStep) {
                  $scope.currentSecao = null;
                  $scope.currentSetor = null;
                }

                if (subStep == 'setor') {
                  $scope.currentSecao = null;
                }
              });

              $scope.inativateSetor = function(setor) {
                if (!confirm('Deseja excluir o setor? Esta ação é irreversível!')) {
                  return;
                }

                $scope.shared.pendingRequests++;
                SetorResource.delete({}, {id: setor.id})
                             .$promise
                             .then(function(response) {
                               $scope.shared.setMapeamento($scope.currentMapeamento.id);
                               notifications.notifySuccess('Setor excluído com sucesso!');
                             })
                             .catch(function(error) {
                               notifications.notifyError('Falha ao excluir setor!', error.data.errors);
                             })
                             .finally(function() {
                               $scope.shared.pendingRequests--;
                             });
              };

              $scope.inativateSecao = function(secao) {
                if (!confirm('Deseja excluir a seção? Esta ação é irreversível!')) {
                  return;
                }

                $scope.shared.pendingRequests++;
                SecaoResource.delete({}, {id: secao.id})
                             .$promise
                             .then(function(response) {
                               notifications.notifySuccess('Seção excluída com sucesso!');
                               setSetor($scope.currentSetor.id);
                             })
                             .catch(function(error) {
                               notifications.notifyError('Falha ao excluir seção!', error.data.errors);
                             })
                             .finally(function() {
                               $scope.shared.pendingRequests--;
                             });
              };

              var currentTime = '';
              $scope.updateTime = function() {
                currentTime = new Date().getTime();
              };

              $scope.getTime = function() {
                return currentTime;
              };

              $scope.apiUrl = apiUrl;

              $scope.saveSetor = function(setor, mapeamento) {
                $scope.shared.pendingRequests++;

                var url = apiUrl + 'api/v1/local/setor/';

                if ($scope.currentSetor.id) { url += $scope.currentSetor.id; }

                var isNew = !$scope.currentSetor.id;

                SetorResource.save($scope.currentSetor)
                             .$promise
                             .then(function(resp) {
                                notifications.notifySuccess('Setor salvo com sucesso!');
                                $scope.updateTime();

                                if (resp.generatedId) { setor.id = resp.generatedId; }
                                if (isNew) { mapeamento.setores.push(setor); }
                                $scope.setorForm.$setPristine();
                                setor.onEdit = false;

                                setorOriginal = {};
                                for (var p in $scope.currentSetor) {
                                  setorOriginal[p] = $scope.currentSetor[p];
                                }

                                if (setor.secaoUnica) {
                                  $scope.currentSetor = null;
                                  window.history.back();
                                  $scope.shared.setMapeamento($scope.currentMapeamento.id);
                                  return;
                                }

                                $scope.shared
                                      .setMapeamento($scope.currentMapeamento.id)
                                      .then(function() {
                                        restaurarSetorEmEdicao(setor.id);
                                      });
                              })
                             .catch(function(resp) {
                               notifications.notifyError('Falha ao salvar setor!', resp.data.errors);
                             })
                             .finally(function() {
                               $scope.shared.pendingRequests--;
                             });
              };

              function restaurarSetorEmEdicao(id) {
                $scope.shared.pendingRequests++;
                SetorResource.get({}, {id: id})
                             .$promise
                             .then(function(response) {
                               $scope.currentSetor = new locais.Setor(response);
                             })
                             .catch(function(errors) {
                               notifications.notifyError('Falha ao obter setor!', resp.data.errors);
                             })
                             .finally(function() { $scope.shared.pendingRequests--; });
              }

              function getFeedbackClasses(form, isValid) {
                return {
                  'has-feedback': form.$dirty,
                  'has-error': form.$dirty && !isValid,
                  'has-success': form.$dirty && isValid
                };
              }

              $scope.validationClassNomeSecao = function(secao, form) {
                if (!secao) {
                  return {};
                };
                return getFeedbackClasses(form, secao.nome);
              };

              function reset() {
                $scope.currentSetor = null;
                $scope.currentSecao = null;
              }

              $scope.$watch('currentMapeamento', function(newValue, oldValue) {
                reset();
              });

              var secaoOriginal = null;

              $scope.saveSecao = function(secao, setor, form) {
                var isNew = !secao.id;
                var callback = function() {
                  if (!isNew) {
                    setor.secoes = _.filter(setor.secoes, function(s) {
                      return s.id != secao.id;
                    });
                  }
                  setor.secoes.push(secao);
                  form.$setPristine();
                  secao.onEdit = false;
                  secaoOriginal = {};
                  for (var p in secao) {
                    secaoOriginal[p] = secao[p];
                  }
                };

                saveEntity($scope.currentSecao, SecaoResource, 'Seção salva com sucesso!',
                           'Falha ao salvar seção!', callback, $scope);
              };

            }]};
  });

})(window, angular.module(DataClick.apiModuleName),
    DataClick.Notifications, DataClick.Models, DataClick.Models.Locais, _, $,DataClick.SaveEntity,
    DataClick.buildInputValidationClasses, window.dataclickApiUrl, DataClick.Models.Locais.Mapeamento);
