(function(global, app, Mapeamento, _) {

  app.directive('dataclickMapeamentoDetalhes', function() {
    return {
            templateUrl: global.dataclickApiUrl + 'directives/dataclickMapeamentoDetalhes.html',
            scope: {'mapeamentoId': '=mapeamentoId'},
            controller: ['$scope', 'MapeamentoResource', '$location', '$rootScope',
              function($scope, MapeamentoResource, $location, $rootScope) {
                $scope.currentMapeamento = null;
                $scope.pendingRequests = 0;

                $scope.apiUrl = global.dataclickApiUrl;

                var currentTime = new Date().getTime();

                $scope.updateTime = function() {
                  currentTime = new Date().getTime();
                };

                $scope.getTime = function() {
                  return currentTime ;
                };

                function setData() {
                  var currentUrl = $location.path().substring(1);
                  var segments = currentUrl.split('/');
                  var step = segments[0];

                  if (!step.contains('mapeamentoDetalhes')) {
                    return;
                  }

                  var mapeamentoId = segments[1];                  
                  if(mapeamentoId) {
                    setMapeamento(mapeamentoId);
                  }    
                }

                $rootScope.$on('$locationChangeStart', function(event, newUrl, oldUrl) {
                  setData();         
                });

                function setMapeamento(id) {
                  $scope.pendingRequests++;

                  MapeamentoResource.get({}, {id: id})
                                    .$promise
                                    .then(function(response) {
                                      $scope.currentMapeamento = new Mapeamento(response);
                                      _.each($scope.currentMapeamento.setores, function(setor) {
                                        setor.expandido = true;
                                        _.each(setor.secoes, function(secao) {
                                          secao.expandida = true;
                                        });
                                      });
                                      $scope.updateTime();
                                    })
                                    .finally(function() {
                                      $scope.pendingRequests--;
                                    });
                }

                $scope.$watch('mapeamentoId', function(newValue, oldValue) {
                  if (newValue) { setMapeamento(newValue); }
                });

                setData();
              }]
          };
  });
})(window, angular.module(DataClick.apiModuleName), DataClick.Models.Locais.Mapeamento, _);
