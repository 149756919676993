(function(Models, _) {

  if (!Models.Locais) { Models.Locais = {}; }

  Models.Locais.Secao = function(secao) {
    var self = this;

    if (secao) {
      for (prop in secao) { this[prop] = secao[prop]; }

      this.filas = _.chain(secao.filas)
                    .groupBy(function(item) { return item.identificador; })
                    .map(function(val, key) { return new Models.Locais.IdentificadorFila(self.id, key, val); })
                    .value();

      this.setorId = secao.setor.id;
      this.onEdit = false;
    }else {
      this.filas = [];
      this.nome = '';
      this.onEdit = true;
    }

    var dataBackup = '';

    this.edit = function() {
      self.onEdit = true;
      dataBackup = self.nome;
    };

    this.cancelEdit = function() {
      self.onEdit = false;
      self.nome = dataBackup;
    };

    this.obterQuantidadeLugares = function() {
      if (self.filas.length == 0) {
        return 0;
      }

      return _.reduce(self.filas, function(acc, i) {
        return acc + i.obterQuantidadeLugares();
      }, 0);
    };

    this.adicionarFila = function() {
      var fila = new Models.Locais.IdentificadorFila();
      fila.secaoId = self.id;
      self.filas.push(fila);
    };

    this.removerFila = function(fila) {
      if (!confirm('Deseja remover a fila? Essa ação é irreversível.')) {
        return;
      }

      self.filas = _.filter(self.filas, function(f) {
        return f != fila;
      });
    };

    function getErrors() {
      var errors = [];
      if (!self.nome) { errors.push('Informe o nome.'); }
      return errors;
    }

    this.isValid = function() { return getErrors().length == 0; };
  };

})(DataClick.Models, _);
